// AboutUs.jsx

import React from 'react';
import './About.css';
import Header from '../image/header.jpg'


const About= () => {
    return (
        <section className="about-section" id='about'>
            <div className="about-container">
                <div className="about-content">
                    <h2 className='about-h2'><span>Fawei & Partners-at-Law </span> <br/> Who are we ?</h2>
                    <p>We are a law firm with diverse but cognate experience in law practice and legal services. The firm has and manages a well-established client base and growing in steps with the developing global economy as it affects Nigeria, where it is situated.</p>
                    <p>The Vision of the firm is to become a World Class Law Firm in specific areas of legal practice with a focus on meeting the needs of our clients as well as impacting positively the global economy and social order through law.</p>
                    <a href="#contact" className="btn">Contact Us</a>
                </div>
                <div className="about-image">
                    <img src={Header} alt="Law Firm Office" />
                </div>
            </div>
        </section>
    );
};

export default About;
