// src/Navbar.js

import React, { useState } from 'react';
import './Navbar.css';
import Logo from '../image/logo.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className='fawei'>
        <a href="#home" className="navbar-logo"><img src={Logo} width='80px' alt='logo'/></a>
        <p className='fawei_logo'>Fawei & Partners-at-Law</p>
        </div>
        
        <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
          <a href="#home" className="navbar-link">Home</a>
          <a href="#about" className="navbar-link">About</a>
          <a href="#practice" className="navbar-link">Practice-Areas</a>
          <a href="#attorneys" className="navbar-link">Lawyers</a>
          <a href="#contact" className="navbar-link">Contact</a>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          <div className={`burger ${isOpen ? 'active' : ''}`}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
