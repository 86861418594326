import React from 'react'
import Navbar from './Navbar'
import './Home.css'

const Home = () => {

  return (
    <header id='home'>
      <Navbar/>
       <h1>Help to reclaim your life and freedom</h1>
       <br/>
      <p>We know how large objects will act, but things on a large scale will not act that way</p>
      <br/>
      <br/>
      <div className='btn-div'>
      <a href="#practice" className="btn">Our Services</a>
      <a href="#about" className="btn clear">Learn More</a>
      </div>
    </header>
  )
}
 
export default Home
