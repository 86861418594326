
import React from 'react';
import './Practice.css';


const practiceData = [
    { id: 1, icon: '📞', description: 'Information Communication Technology & Artificial Intelligence law' },
    { id: 2, icon: '🌐', description: 'Immigration Law' },
    { id: 3, icon: '🗳️', description: 'Election law & other political related matters ' },
    { id: 4, icon: '⚖️', description: 'Constitutional law & practice' },
    { id: 5, icon: '🛠️', description: 'Labour & Sindustrial relations' },
    { id: 6, icon: '🌐', description: 'Human rights litigation' },
    { id: 7, icon: '⚓', description: 'Admiralty & maritime law' },
    { id: 8, icon: '⚡', description: 'Energy & Environment law' },
    { id: 9, icon: '💼', description: 'Arbitration, mediation & conciliation law' },
    { id: 10, icon: '💰', description: 'Banking, Finance and taxation law & practice' },
    { id: 11, icon: '🏢', description: 'Corporate administration & secretarial services' },
    { id: 12, icon: '🛎️', description: 'Other expert services developed from time to time' },
];

const Practice = () => {
    return (
        <section className="practice-section" id='practice'>
          <h1>Our Practice Areas</h1>
          <p className='about-practice'>The firm offers a full range of legal services including but not limited to consulting services, cross-border and local dispute resolution, civil and criminal litigation, taxation, finance and investment consultancy services, corporate administration, real estate etc. These range of services are offered with more focus on the respective subject areas of expertise possessed by counsel in the firm which includes but not limited to:</p>
            <div className="practice-container">
                {practiceData.map(practice => (
                    <div key={practice.id} className="practice-box">
                        <div className="practice-icon">{practice.icon}</div>
                        <p className="practice-description">{practice.description}</p>
                    </div>
                ))}
            </div>
            <p className='about-practice'>These range of services have attracted local, and international corporate entities as well as individuals that cut across a broad range of sectors, ranging from emerging businesses to fully developed and operational corporate entities as well as public sector organizations.</p>
        </section>
    );
};

export default Practice;
