// NewsletterSection.jsx

import React, { useState } from 'react';
import './Newsletter.css';

const NewsletterSection = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Submitting email: ${email}`);
    setEmail('');
  };

  return (
    <section className="newsletter-section">
      <div className="newsletter-content">
        <h2>Subscribe to Our Newsletter</h2>
        <p>Stay updated with our latest news and promotions!</p>
        <form onSubmit={handleSubmit}>
          <div className="newsletter-form">
            <input 
              type="email"
              placeholder="Your email"
              className='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Subscribe</button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default NewsletterSection;
