import Home from './components/Home'
import About from './components/About'
import Practices from './components/Practice'
import Attorneys from './components/Attorneys'
import Contactus from './components/Contactus'
import NewsletterSection from './components/Newsletter'
import Footer from './components/footer'
import BackToTopButton from './components/BackToTop'
import './App.css'


  
function App() {
  return (
  <>
    <Home/>
    <About/> 
    <Practices/>
    <Attorneys/>  
    <Contactus/>
    <NewsletterSection/>
    <Footer/>
    <BackToTopButton/>
  </>
  );
}

export default App;
