// src/Footer.js

import React from 'react';
import linkedin from "../image/linkedin.svg"
import facebook from "../image/square-facebook.svg"
import instagram from "../image/square-instagram.svg"
import twitter from "../image/square-twitter.svg"
import './footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-top'>
        <div className='footer-icon'>
         <h2>Fawei & Partners-at-Law</h2>
        </div>
        <div className='footer-socials'>
        <a href="/" className="footer-logo"><img src={linkedin} width='30px' alt='logo'/></a>
        <a href="https://web.facebook.com/people/Fawei-Partners-at-Law/61560043807290/" className="footer-logo"><img src={facebook} width='30px' alt='logo'/></a>
        <a href="/" className="footer-logo"><img src={instagram} width='30px' alt='logo'/></a>
        <a href="/" className="footer-logo"><img src={twitter} width='30px' alt='logo'/></a>
        </div>
      </div>
      <div className='footer-content'>
      
     <a href='#home'>Home</a>
     <a href='#about'>About</a>
     <a href='#practice'>Practice Areas</a>
     <a href='#attorneys'>Our Lawyers</a>
  
      </div> 
      <div className='fawei_bottom-link'>
    <div className='copyrights'>
    <p>&copy; 2024 Fawei & Partners-at-Law. All rights reserved.</p>
    </div>
    <div className='developers'>
      <a href='https://www.linkedin.com/company/maziv-technologies-limited/'><span>Designed By:</span> Maziv Technologies</a>
    </div>
      </div>
    
 </footer>
  );
};

export default Footer;
