import React, { useRef, useState } from 'react';

import emailjs from '@emailjs/browser';
import Alert from './Alert';

import './Contactus.css';


const Contactus = () => {
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_3q2u24i', 'template_4vwvwie', form.current, {
        publicKey: 'nUzvpT3ZMb9Yi3PkT',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  const [isAlertVisible, setAlertVisible] = useState(false);

  const showAlert = () => {
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 4000);
  };

  const hideAlert = () => {
    setAlertVisible(false);
  };

  return (
    <div className='contact-section' id='contact'>
      <h3 className='contact-h3' >Contact Us </h3>
      <h1 className='contact-h1'>Make an appointment</h1>
       <form className="contact-form" ref={form} onSubmit={sendEmail}>
      <div className="form-group">
        
        <input type="text" id="name" name="user_name" placeholder="Your Name" required />
      </div>
      <div className="form-group">
       
        <input type="tel" id="phone" name="user_number" placeholder="Your Phone Number" required />
      </div>
      <div className="form-group">
        
        <input type="email" id="email" name="user_email" placeholder="Your Email" required />
      </div>
      <div className="form-group">
        
        <input type="text" id="address" name="user_address" placeholder="Your Address" required />
      </div>
      <div className="form-group">
        
        <textarea id="message" name="message" placeholder="Case Description" rows="5" required></textarea>
      </div>
      <button type="submit" onClick={showAlert} value="Send" className='bttn'> Send Message</button>
      {isAlertVisible && <Alert message="Success!" onClose={hideAlert} />}
    </form>
    </div>
);
}

export default Contactus;
