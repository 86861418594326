import React from 'react';
import './Attorneys.css';
import Law1 from '../image/law1.jpg';
import Law2 from '../image/law2.jpg';
import Law3 from '../image/law3.jpg';
import Law4 from '../image/law4.jpg';
import Law5 from '../image/law5.jpg';
import Law6 from '../image/law6.jpg';
import Law7 from '../image/law7.jpg';

const Attorneys = () => {
  return (
    <div className='attorneys-section' id='attorneys'>
      <h1 className='head-text'>Meet Our Lawyers</h1>
         <div className="attorney-1">
      <div className="profile-image">
        <img src={Law1} alt="Profile"/>
      </div>
      <div className="content">
        <div className='clr-bg'>
        <h2>FAWEI, PEREKEDOU JAMES</h2>
        <h3>Head of chambers</h3>
        </div>
        <br/>
        <p>Fawei, Perekedou James is a legal practitioner called to the Nigeria Bar on the 17th day of January 2012. He holds a PhD in Artificial Intelligence in cybercrime law. He is a Notary Public of the Federal Republic of Nigeria.
Dr. P. J. Fawei, is a member of several professional bodies such as the Nigerian Bar Association (NBA), Association of Professional Negotiators and Mediators (APNM), Nigerian Institute of Chartered Arbitrators (NICArb), Computer Forensic Institute of Nigeria (CFIN), Nigerian Institute of Management (Chartered), Association of Nigerian Authors (ANA), and several others.
</p>
<br/>
<p>As a member of the Yenagoa Branch of the Nigerian Bar Association, P. J. Fawei has served in different capacities which include Secretary, Young Lawyers Forum (2015 – 2017); Publicity Secretary, Yenagoa Branch (2017 – 2019); Secretary, Yenagoa Branch (2021 – 2023), and several other committees including the Human Rights Committee, and Law Week Planning Committee. He has also served as a two-time Legal Adviser in the Bayelsa State Chapter of the Association of Nigerian Authors.</p>
        <div className="contact-info">
          <p><a href="mailto:perejames2012@gmail.com">Message Me</a></p>
          </div>
      </div>
    </div>
    
    <div className='attorney-2'>
      <div className='profile-1'>
      <div className='clr-bg'>
      <h2>Pavilla Okus Tangbei, Esq.</h2>
        <h3>Associate (Counsel in chambers)</h3>
      </div>
      <br/>
        <p>Pavilla Okus Tangbei is a dedicated and knowledgeable lawyer with 4 years of experience in practicing law. Known for a meticulous approach to legal research and a strong commitment to client advocacy, Pavilla has successfully represented clients in various legal matters, delivering favorable outcomes through a combination of analytical skills and strategic negotiation.</p>
        <div className="contact-info">
          <p><a href="mailto:pavillatan13@gmail.com">Message Me</a></p>
        </div>
      </div>
      
      <div className='profile-2'>
      <img src={Law2} alt="Profile"/>
      </div>
      <div className='profile-3'>
        <div>
          <h4>Education:</h4>
          <p>- Igbenedion University Okada, LL.B, 2020
             - Nigerian Law School, Enugu State, Called to the  Nigerian Bar, 2021
             - LLM in view
          </p>
        </div>
        <br/>
        <div>
          <h4>Professional Certification:</h4>
          <p>Chartered Arbitrator
          </p>
        </div>
        <br/>
        <div>
          <h4>Professional Experience:</h4>
          <p>- Associate Lawyer, Fawei and Partners-At-Law
  - Provided legal counsel and representation in various legal matters.
  - Conducted thorough legal research and drafted legal documents.
  - Represented clients in court, achieving successful verdicts in complex cases.
  - Assisted in negotiations and settlements, ensuring the best outcomes for clients.

          </p>
        </div>
        <br/>
       <div>
        <h4>Area Of Expertise</h4>
        <p>- Currently developing expertise in Petroleum and Environmental Law</p>
       </div>
      </div>
      
      
    </div>
    <div className="attorney-1">
      <div className="profile-image">
        <img src={Law3} alt="Profile"/>
      </div>
      <div className="content">
      <div className='clr-bg'>
      <h2>BRIGHT AMADI OKEH, Esq.</h2>
        <h3>Associate (Counsel in chambers) </h3>
      </div>
        
        <br/>
        <p>BRIGHT AMADI OKEH is a core Litigation practicing Lawyer and an Associate Counsel of Fawei & Partners at Law, Yenagoa, Bayelsa State.
He is an LLM candidate of the Rivers State University. He is a graduate of the prestigious Niger Delta University, Faculty of Law. Wherein he graduated as one of the best in his class (Top 5%). He was a former Justice of the Supreme Court of Niger Delta University, Faculty of Law. He was a former CLASFON president, Faculty of Law, Niger Delta University.

</p>
<br/>
<p>He is a Chartered Mediator and a Conciliator and an Associate of the Institute of Chartered Mediators and Conciliators. He has garnered considerable experience in election petitions having formed part of the legal team who represented a member of the House of Representatives and several members of the Bayelsa State Houses of Assembly in the 2023 general elections in Bayelsa State.
He has spoken in several Christian Summit and has an undying passion for the things of God.
His core area of interest in Law Spans across; Admiralty and Shipping Law, Trade and Labour Dispute, Election Law and intellectual property.
</p>

      </div>
    </div>
    <div className='attorney-2'>
      <div className='profile-1'>
      <div className='clr-bg'>
      <h2>MRS. BENJAMIN O. PEACE PRAISE B.Sc. MSc. </h2>
        <h3>HUMAN RESOURCE MANAGER</h3>
      </div>
      <br/>
        <p>Highly experienced Human Resource Manager with over 4 years of experience at Fawei & Partners-at-Law, possessing a strong educational background in Office and Information Management (BSc) and Human Resource Management (MSc). Proven track record of effectively managing human resources, driving organizational growth, and fostering a positive work environment. Committed to professional excellence, a Graduate member of the Nigerian Institute of Management (NIM)</p>
        <div className="contact-info">
          <p><a href="mailto:praiseaguamah@gmail.com">Message Me</a></p>
        </div>
      </div>
      
      <div className='profile-2'>
      <img src={Law4} alt="Profile"/>
      </div>
      <div className='profile-3'>
        <div>
          <h4>Education:</h4>
          <p>- BSc, Office and Information Management
             - MSc, Human Resource Management
          </p>
        </div>
        <br/>
        <div>
          <h4>Professional Certification:</h4>
          <p>- Graduate Member, Nigerian Institute of Management (NIM)
          </p>
        </div>
        <br/>
        <div>
          <h4>Professional Experience:</h4>
          <p>- Human Resource Manager, Fawei & Partners-at-Law (2019 - Present)
- Managed the recruitment, training, and development of staff
- Implemented effective HR strategies to enhance employee engagement and retention
- Developed and maintained policies and procedures to ensure compliance.

          </p>
        </div>
        <br/>
       <div>
        <h4>Area Of Expertise</h4>
        <p>- Human Resource Management
- Recruitment/Training and Development
- Employee Relations and Engagement
- Policy Development and Implementation
- Risk Management 
- Project Planning and Management 
</p>
       </div>
       </div>
    </div>
    <div className="attorney-1">
      <div className="profile-image">
        <img src={Law5} alt="Profile"/>
      </div>
      <div className="content">
      <div className='clr-bg'>
      <h2>Mathias, Opamo-owei Francis, Esq. </h2>
        <h3>Associate (Counsel in chambers)</h3>
      </div>
        
        <br/>
        <p>Mathias, Opamo-owei Francis Esq., is a lawyer with growing expertise in Human Right Advocacy and Legal Consulting. Committed to striking a fair balance between being a commercially aware lawyer whose goal is to be at tune with all-time emerging legal traditions and to be amongst the best in the profession and also an embodiment of public trust. Currently works as an Associate at Fawei & Partners-at-Law and dedicated to delivering premium legal services to esteemed clients by providing practical solutions to business and legal needs of individual clients.  
</p>
<br/>
<p>Working as a Legal consultant has exposed him to the honorable nature of solving problems and he is resolved to uphold this benchmark as a legal practitioner.
Passionately developing expertise in Election Law and practice, Human Rights, Public Policy, Petroleum Law and Alternative Dispute Resolution. 
A value-oriented and a dignified Learner.
</p>
    </div>
    </div>
    <div className='attorney-2'>
      <div className='profile-1'>
      <div className='clr-bg'>
      <h2>ABEZI PEACE INIPAMI B.Sc.  </h2>
        <h3>OFFICE ASSISTANT/SECRETARY </h3>
      </div>
        <br/>
        <p>Highly experienced Office Assistant/secretary with over 2 years of experience at Fawei & Partners-at-Law, possessing a strong educational background from Madonna University Nigeria in Sociology and Anthropology (BSc). Proven track record of effective secretarial duties, driving the organizational growth, and fostering a productive work environment. 
Looking forward to furthering secretarial skills through the membership of the Nigerian Institute of Management. Possess a kill interest in social well-being.   
</p>
        <div className="contact-info">
          <p><a href="mailto:peaceabezi.faweiandpartners@gmail.com">Message Me</a></p>
        </div>
      </div>
      
      <div className='profile-2'>
      <img src={Law6} alt="Profile"/>
      </div>
      <div className='profile-3'>
        <div>
          <h4>Education:</h4>
          <p>- BSc, Sociology and Anthropology 
          </p>
        </div>
        <br/>
        <div>
          <h4>Professional Certification:</h4>
          <p>- Nigerian Institute of Management (NIM)-in view
          </p>
        </div>
     
      </div>
    </div>
    <div className="attorney-1">
      <div className="profile-image">
        <img src={Law7} alt="Profile"/>
      </div>
      <div className="content">
      <div className='clr-bg'>
      <h2>BRIGHT AMADI OKEH, Esq.</h2>
        <h3>Associate (Counsel in chambers) </h3>
      </div>
        
        <br/>
        <p>BRIGHT AMADI OKEH is a core Litigation practicing Lawyer and an Associate Counsel of Fawei & Partners at Law, Yenagoa, Bayelsa State.
He is an LLM candidate of the Rivers State University. He is a graduate of the prestigious Niger Delta University, Faculty of Law. Wherein he graduated as one of the best in his class (Top 5%). He was a former Justice of the Supreme Court of Niger Delta University, Faculty of Law. He was a former CLASFON president, Faculty of Law, Niger Delta University.
</p>
<br/>
<p>He is a Chartered Mediator and a Conciliator and an Associate of the Institute of Chartered Mediators and Conciliators. He has garnered considerable experience in election petitions having formed part of the legal team who represented a member of the House of Representatives and several members of the Bayelsa State Houses of Assembly in the 2023 general elections in Bayelsa State.
He has spoken in several Christian Summit and has an undying passion for the things of God.
His core area of interest in Law Spans across; Admiralty and Shipping Law, Trade and Labour Dispute, Election Law and intellectual property.
</p>
    </div>
    </div>
    </div>
  );
};

export default Attorneys;
